*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none;
  border: 0;
}

* {
  font-family: 'Cairo', sans-serif;
  direction: rtl;
}

input {
  color: #000 !important;
}

body {
  background-color: black;
  background-image: url("/images/siteImages/bg.jpg");
  background-repeat: repeat; /* This will repeat the texture both horizontally and vertically */
  background-size: auto; /* Keeps the original size of the texture */
  color: white;

}

.whiteContainer {
  background-color: rgba(255, 255, 255, .8) !important;
  color: #000;
}


.container {
  background-color: rgba(44, 44, 44, .7);
  left: 0;
  right: 0;
  margin: 20px auto 0 auto;
  text-align: center;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  padding: 30px 10px;
}

.menuContainer {
  left: 0;
  right: 0;
  margin: 20px auto;
  text-align: center;
  /* border:1px solid red */
}

.appLogo {
  margin: 0 0 20px 0;
}

.developerLogo {
  margin: 20px 0;
  text-align: center;
}

.firstHPAD {
  margin: 20px;
}

.menuTopButtons {
  z-index: 100;
  overflow: hidden;
  top:20px;
  display: flex;
  flex-direction: row;
  gap:5px;
}

.menuTopButtons>div {
  font-size: 14px;
  padding: 0 15px;
  background-color: #e24c12;
  color: #FFF;
  height: 60px;
  line-height: 60px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
.menuTopButtons>div:last-of-type{
  background-color: #000;
  flex-grow: 1;
  padding:0 3px;
}
.menuTopButtons div img {
  vertical-align: middle;
}

.menuTopButtons a {
  color: #FFF;
  text-decoration: none;
}

.messageAbsolute{
  position: fixed;
  top:0;
  left:0;
  right:0;
  width:100%;
  background-color: #000;
  z-index: 999999;
}



.categorySec .NofI {
  text-align: right;
  color: #e24c12;
  font-size: 14px;
  height: 40px;
  line-height: 5px;
}

.categorySec .ItemCat {
  text-align: right;
  height: 80px;
  line-height: 100px;
  font-weight: 500;
}

.categorySec .catIndex {
  position: absolute;
  right: -90px;
  top: -40px;
  color: #FFF;
  height: 200px;
  line-height: 200px;
  width: 200px;
  padding-right: 120px;
  background-color: #e24c12;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}


.categorySec {
  position: relative;
  overflow: hidden;
  background-color: #FFF;
  color: #000;
  font-weight: 600;
  -webkit-border-top-left-radius: 10px;
  -webkit-border-top-right-radius: 10px;
  -moz-border-radius-topleft: 10px;
  -moz-border-radius-topright: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding-right: 120px;
  height: 120px;
  line-height: 120px;
  font-size: 30px;
}

.categorySecOffers {
  position: relative;
  background: rgb(255, 246, 206);
  background: radial-gradient(circle, rgba(255, 246, 206, 1) 0%, rgba(200, 160, 70, 1) 100%);
  color: #624709;
  font-weight: 600;
  height: 120px;
  font-size: 20px;
  -webkit-border-top-left-radius: 10px;
  -webkit-border-top-right-radius: 10px;
  -moz-border-radius-topleft: 10px;
  -moz-border-radius-topright: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  /* display: flex;
  
  align-items: center;
  justify-content: start; */
  /* hali */
}

.categorySecOffers .ItemCat {
  height: 30px;
  font-weight: 600;
  width:100%;
  margin-right: 20px;
  font-size: 0.8em;
}
.categorySecOffers .ItemCat:first-of-type{
  font-size: 1.1em;
  height: 1.6em;
  font-weight: 900;
}
.categorySecOffers .so {
  position: absolute;
  top: -10px;
  left: 15px;

  /* rotate: -45deg; */

}
.categorySecOffers .so img{
  width: 110px;
}
.categorySecOffers,
.catItems {
  text-align: right;
}

.divider {
  margin-bottom: 20px;
}

.catItems {
  background-color: rgba(21, 26, 21, .9);
  color: #FFF;
  font-weight: 100px;
  padding: 10px;
}

.catItemsOffers {
  background-color: rgba(21, 26, 21, .9);
  color: #FFF;
  font-weight: 100px;
  padding: 10px;
}

.catItems:nth-last-child(1) {
  -webkit-border-bottom-right-radius: 10px;
  -webkit-border-bottom-left-radius: 10px;
  -moz-border-radius-bottomright: 10px;
  -moz-border-radius-bottomleft: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  margin-bottom: 20px !important;
}

.catItemsOffers:nth-last-child(1) {
  -webkit-border-bottom-right-radius: 10px;
  -webkit-border-bottom-left-radius: 10px;
  -moz-border-radius-bottomright: 10px;
  -moz-border-radius-bottomleft: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  margin-bottom: 20px !important;
}

.catItems:nth-child(odd) {
  background-color: rgba(49, 63, 48, .9);
}

.catItemsOffers:nth-child(odd) {
  background-color: rgba(49, 63, 48, .9);
}

@keyframes OfferPriceDiscount {
  0% {
    padding-right: 10px;
    border-right: 0px solid #e24c12;
  }

  50% {
    padding-right: 15px;
    border-right: 2px solid #e24c12;
  }

  100% {
    padding-right: 10px;
    border-right: 0px solid #e24c12;
  }
}

.OfferPriceDiscount {
  border-right: 0px solid #e24c12;
  font-weight: 700;
  animation: OfferPriceDiscount 1.5s ease-in-out infinite;
  -webkit-animation: OfferPriceDiscount 1.5s ease-in-out infinite;
}

.catItemsNoData {
  background-color: rgba(220, 49, 49, 0.9);
  padding: 10px;
  font-size: 22px;
  -webkit-border-bottom-right-radius: 10px;
  -webkit-border-bottom-left-radius: 10px;
  -moz-border-radius-bottomright: 10px;
  -moz-border-radius-bottomleft: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  margin-bottom: 20px;
}

.viewScreenMessage {
  color: #FFF;
  width: calc(100% - 20px);
  font-size: 14px;
  text-align: center;
  margin: 10px;
  padding: 20px;
  border: 2px dotted #e24c12;
}

.itemsNamesAndPricesOffers {
  width: 100%;
}

.itemsNamesAndPricesOffers>div:first-of-type {
  width: 80%;
}

.itemsNamesAndPricesOffers>div {
  width: 20%;
  display: inline-block;
  height: 50px;
  line-height: 50px;
  vertical-align: top;
}
.itemsNamesAndPricesOffers div{
  direction: rtl;
}
.itemsNamesAndPricesOffers div:first-child {
  text-align: right;

}

.itemsNamesAndPricesOffers div:last-child {
  text-align: left;
  font-weight: 700;
  direction: ltr;
}

.itemsNamesAndPricesOffers div:first-child span {
  color: #fff5c6;
  font-weight: 700;
}

.itemsNamesAndPricesOffers div:last-child div {
  height: 100%;
  width: 100%;
  font-size: 10px;
  text-align: left;
}

.itemsNamesAndPricesOffers div:last-child div div {
  height: calc(100% / 2);
  direction: ltr;

}


.itemsNamesAndPricesOffers div .categoryText{
  font-size: .7em;
}







.itemsNamesAndPrices {
  width: 100%;
}

.itemsNamesAndPrices>div {
  width: 50%;
  display: inline-block;
  height: 50px;
  line-height: 50px;
  vertical-align: top;
}

.itemsNamesAndPrices div:first-child {
  text-align: right;

}

.itemsNamesAndPrices div:last-child {
  text-align: left;
  font-weight: 700;
  direction: ltr;
}

.itemsNamesAndPrices div:last-child div {
  height: 100%;
  width: 100%;
  font-size: 10px;
  text-align: left;
}

.itemsNamesAndPrices div:last-child div div {
  height: calc(100% / 2);
  direction: ltr;

}

.itemsNamesAndPrices div:last-child div div:first-of-type {
  font-size: 1.5em;
  line-height: 30px;
}

.itemsNamesAndPrices div:last-child div div:last-of-type {
  line-height: 2em;
  font-size: 1em;
  direction: rtl;

}

.itemsNamesAndPrices div:last-child div div:last-of-type span {
  background-color: rgb(238, 238, 238);
  border-radius: 2px;
  padding: 0 5px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  color: #000;
  text-decoration: line-through 1px red;
  margin-left: 19px;
}


.menuActions {
  margin: 20px 0;
  overflow: auto;
  white-space: nowrap;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;

}
.menuActions div {


  display: inline-block;
  background-color: #e24c12;
  cursor: pointer;
  min-width: 100px;
  margin: 5px 5px 5px 5px;
  padding: 5px 5px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  font-size: 18px;
  color: #fff;

}

.menuActions div div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.menuActions div div * {
  color: white;
  font-size: 50px;
}


.menuActions::-webkit-scrollbar {
  height: 12px;
}

/* Track */
.menuActions::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 100vw;
  -webkit-border-radius: 100vw;
  -moz-border-radius: 100vw;
  -ms-border-radius: 100vw;
  -o-border-radius: 100vw;
  margin-block: 20px
}

/* Handle */
.menuActions::-webkit-scrollbar-thumb {
  background: #e24c12;
  border-radius: 100vw;
  -webkit-border-radius: 100vw;
  -moz-border-radius: 100vw;
  -ms-border-radius: 100vw;
  -o-border-radius: 100vw;

}




.backgroundMusicButton button {
  color: #FFF;
  font-weight: 700;
  cursor: pointer;
  line-height: 38px;
  background-color: transparent;
}

.backgroundMusicButtonHomePage {
  background-color: #e24c12;
  display: inline-block;
  padding: 10px;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

.backgroundMusicButtonHomePage button {
  color: #fff;
  font-weight: 700;
  width: 100%;
  height: 100%;
}










#testbutton {
  width: 150px;
  height: 50px;
  border-radius: 180px;
  position: relative;

  background: linear-gradient(60deg, #f79533, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);
  cursor: pointer;
  line-height: 12px;
}

#testbutton:before {
  content: '';
  z-index: 1;
  position: absolute;
  display: block;
  width: 80%;
  height: 70%;
  top: 15%;
  left: 10%;
  transition: 0.3s opacity ease-in-out;
  filter: blur(15px);
  opacity: 0;
  background: linear-gradient(60deg, #f79533, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);
}

#testbutton:hover:before {
  opacity: 1;
  transition: 0.3s opacity ease-in-out;
  filter: blur(25px);
  background: linear-gradient(60deg, #f79533, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);
}

#testbutton:after {
  content: 'CLICK ME!';
  text-align: center;
  line-height: 40px;
  font-size: 18px;
  color: rgba(235, 235, 235, 1);
  font-weight: bold;
  z-index: 5;
  position: absolute;
  display: block;
  border-radius: 180px;
  width: 92%;
  height: 80%;
  top: 10%;
  left: 4%;
  background-color: rgb(19, 20, 22);
}











@media (min-width: 1px) {

  .container,
  .menuContainer {
    display: none;
  }

  .viewScreenMessage {
    display: block;
  }
}

@media (min-width: 250px) {

  .container,
  .menuContainer {
    width: 90%;
    display: block;
  }

  .menuTopButtons {
    /* width: 90%; */
  }

  .viewScreenMessage {
    display: none;
  }
}

@media (min-width: 576px) {}

@media (min-width: 768px) {

  .container,
  .menuContainer {
    width: 600px;
  }

  .menuTopButtons {
    width: 600px;
  }
}

@media (min-width: 992px) {}

@media (min-width: 1200px) {}

@media (min-width: 1400px) {}


.openMenuButton {
  width: 100%;
}

.openMenuButton button {
  width: 40px;
  height: 30px;
  font-weight: 500;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.btn-64,
.btn-64 *,
.btn-64 :after,
.btn-64 :before,
.btn-64:after,
.btn-64:before {
  border: 0 solid;
  box-sizing: border-box;

}

.btn-64 {
  -webkit-tap-highlight-color: transparent;
  -webkit-appearance: button;
  background-color: #000;
  background-image: none;
  color: #fff;
  cursor: pointer;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
    Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif,
    Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 100%;
  line-height: 1.5;
  margin: 0;
  -webkit-mask-image: -webkit-radial-gradient(#000, #fff);
  padding: 0;
}

.btn-64:disabled {
  cursor: default;
}

.btn-64:-moz-focusring {
  outline: auto;
}

.btn-64 svg {
  display: block;
  vertical-align: middle;
}

.btn-64 [hidden] {
  display: none;
}

.btn-64 {
  background: none;
  box-sizing: border-box;
  color: #000;
  display: block;
  font-weight: 900;
  -webkit-mask-image: none;
  padding: 1.5rem 4rem;
  position: relative;
  text-transform: uppercase;
}

.btn-64 span {
  background-color: #FFF;

  border: 1px solid;
  border-radius: 999px;
  display: grid;
  inset: 0;
  place-items: center;
  pointer-events: none;
  position: absolute;

}

.btn-64 span {
  -webkit-animation: heartBeat 2s linear infinite;
  animation: heartBeat 2s linear infinite;
}

@keyframes heartBeat {
  0% {
    transform: scale(1);

  }

  10% {
    transform: scale(1.3);
  }

  15% {
    transform: scale(1);
    background-color: #e24c12;
  }

  20% {
    transform: scale(1.3);
  }

  30% {
    transform: scale(1);
  }
}


/* width s*/
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 100vw;
  -webkit-border-radius: 100vw;
  -moz-border-radius: 100vw;
  -ms-border-radius: 100vw;
  -o-border-radius: 100vw;
  margin-block: 20px
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #e24c12;
  border-radius: 100vw;
  -webkit-border-radius: 100vw;
  -moz-border-radius: 100vw;
  -ms-border-radius: 100vw;
  -o-border-radius: 100vw;

}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(248, 50, 0);
}

.menusCarousel {
  width: 100%;
  height: auto;
  overflow: hidden;
}


/*
//////////////////// Start of LANGUAGES
*/

.languages button {
  margin: 5px;
  padding: 10px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.goToTop {
  position: fixed;
  right: 0;
  bottom: 0;
  margin-bottom: 20px;
  margin-right: 20px;
  z-index: 100;
  font-size: 50px;
  animation: goToTop 1.5s ease-in-out infinite;
  -webkit-animation: goToTop 1.5s ease-in-out infinite;
}

.goToTop button {
  background-color: transparent;
  color: rgb(248, 50, 0);

}

@keyframes goToTop {
  0% {
    margin-bottom: 30px;
  }

  50% {
    margin-bottom: 20px;
  }

  100% {
    margin-bottom: 30px;
  }
}


.marquee {
  overflow: hidden;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  display: flex;
}

.marquee span{
  font-size: 1em;
  white-space: nowrap;
  text-transform: uppercase
}